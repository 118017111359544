<template lang="pug">
  .test-layout
    //- Name(:disableField="true")
    //- Patronym(:disableField="true")
    //- BaseBtn(type="outline" icon="password" iconSize="15" size="sm") Изменить пароль
    q-input(
      v-model="value"
      maxlength="3"
      )
</template>

<script>
export default {
  components: {
    Name: () => import('@/views/PersonalArea/userSetting/Name.vue'),
    Patronym: () => import('@/views/PersonalArea/userSetting/Patronym.vue'),
  },
  data() {
    return {
      value: ''
    }
  }
}
</script>

<style lang="scss">
  .test-layout {
    padding: 30px;
    width: 400px
  }
</style>